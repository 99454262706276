import React, { PureComponent } from "react"

import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

import Image from "../Image/Image"
import Boton from "../Boton/Boton"

import STYLES from "./Gallery.module.scss"

const prevIndex = state => (state.index - 1) % state.images.length
const nextIndex = state =>
  (state.index + state.images.length + 1) % state.images.length

class Gallery extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      index: 0,
      isOpen: false,
      images: props.images,
    }

    this.renderLightBox = this.renderLightBox.bind(this)
    this.openLightBox = this.openLightBox.bind(this)
    this.closeLightbox = this.closeLightbox.bind(this)
    this.movePrev = this.movePrev.bind(this)
    this.moveNext = this.moveNext.bind(this)
  }

  openLightBox(index) {
    this.setState({
      index: index,
      isOpen: true,
    })
  }

  renderLightBox() {
    const { images } = this.state
    return (
      <Lightbox
        mainSrc={images[this.state.index].content}
        nextSrc={images[nextIndex(this.state)]?.content}
        prevSrc={images[prevIndex(this.state)]?.content}
        imageTitle={this.props.titulo}
        imageCaption={images[this.state.index].caption}
        onCloseRequest={this.closeLightbox}
        onMovePrevRequest={this.movePrev}
        onMoveNextRequest={this.moveNext}
        nextLabel="Siguiente"
        prevLabel="Anterior"
        zoomInLabel="Zoom in"
        zoomOutLabel="Zoom out"
        closeLabel="Cerrar"
        reactModalStyle={{
          zIndex: 5000,
        }}
      />
    )
  }

  closeLightbox() {
    this.setState({ isOpen: false })
  }

  movePrev() {
    this.setState(prevState => ({
      index: prevIndex(prevState),
    }))
  }

  moveNext() {
    this.setState(prevState => ({
      index: nextIndex(prevState),
    }))
  }

  render() {
    return (
      <>
        <div className="row justify-content-center text-center p-3">
          {this.state.images
            .slice(0, this.props.amountToShow)
            .map((image, index) => {
              return (
                <div
                  className="col-sm-6 col-md-4 col-lg-3 pointer m-3 mx-auto"
                  key={index}
                  onClick={() => this.openLightBox(index)}
                >
                  <Image className={STYLES.imageWrapper} src={image.src} />
                </div>
              )
            })}
        </div>
        {this.state.isOpen && this.renderLightBox()}
        {!!this.props.leerMas ? (
          <Boton text="LEER MÁS" linkTo={this.props.leerMas} />
        ) : (null && {/*(
          <Boton text="DESCUBRE OTROS SERVICIOS" linkTo="/servicios" />
        )*/})
        }
      </>
    )
  }
}

export default Gallery
