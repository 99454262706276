import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons"

// #region DECORACION
const Linea = ({ className, align, primary, ...rest }) => (
  <div>
    <hr
      className={`d-none d-md-block ${className}`}
      align={align}
      style={{
        background: `var(--${!primary ? "secundary" : "primary"})`,
        border: `solid 7px var(--${!primary ? "secundary" : "primary"})`,
        width: "75px",
        ...rest,
      }}
    />
    <hr
      className={`d-md-none ${className}`}
      align={"center"}
      style={{
        background: `var(--${!primary ? "secundary" : "primary"})`,
        border: `solid 7px var(--${!primary ? "secundary" : "primary"})`,
        width: "75px",
        ...rest,
      }}
    />
  </div>
)

const Dot = props => (
  <div className={`mt-md-1 mt-sm-3 ml-3 d-none d-md-block ${props.className}`}>
    <span
      style={{
        height: "10px",
        width: "10px",
        backgroundColor: props.color || "var(--secundary)",
        borderRadius: "50%",
        display: "inline-block",
      }}
    />
  </div>
)

const Arrow = props => (
  <div className="col d-none d-sm-block">
    <div className={`row justify-content-${props.left ? "start" : "end"}`}>
      <div
        className={`col-lg-2 p-1 col-md-8 col-sm-12 pointer ${props.className}`}
        onClick={props.onClick}
        style={{
          ...props.style,
        }}
      >
        <div className="row text-center">
          <div className="col">
            <div className="py-5">
              <FontAwesomeIcon
                icon={props.left ? faChevronLeft : faChevronRight}
                size="2x"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
// #endregion DECORACION

// #region WRAPPERS
const CuadroBordeado = props => (
  <div
    className={`p-5 ${props.className}`}
    style={{
      borderRadius: `${props.voltear ? "0 30% 0 30%" : "30% 0 30% 0"}`,
      ...props.style,
    }}
  >
    {props.children}
  </div>
)

const TextoFoto = props => (
  <div className={`row my-auto ${props.className}`} style={props.style}>
    <div className="col-lg-5 col-sm-12">{props.children}</div>
    <div className="col-lg-1"></div>
    <div className="col-lg-6 col-sm-12">
      {props.imgs.map((img, i) => (
        <div>
          <div className="row align-content-center justify-content-center">
            <CuadroBordeado
              voltear={i % 2}
              className={`embed-responsive ${i % 2 === 1 &&
                "ml-3 ml-lg-5"} embed-responsive-1by1 w-75`}
              style={{
                zIndex: 3,
                backgroundImage: `url(${img})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "auto 100%",
                backgroundPosition: "center",
              }}
            ></CuadroBordeado>
            <CuadroBordeado
              voltear={i % 2}
              className={`bg-transparent ${i % 2 === 0 &&
                "ml-3 ml-lg-5"} mt-3 mt-lg-5 embed-responsive embed-responsive-1by1 w-75 position-absolute`}
              style={{ border: "10px solid var(--secundary)", opacity: "0.2" }}
            ></CuadroBordeado>
          </div>
          {i + 1 < props.imgs.length && <div className="row py-5"></div>}
        </div>
      ))}
    </div>
  </div>
)

const Li = props => (
  <div className={`row ${props.className}`}>
    <Dot color={`var(--${props.color})`} />
    <div className="col-lg-11 col-md-11 col-sm-12">
      <P>
        <span className={`h1 text-${props.color} d-md-none m-0 p-0`}>
          {"• "}
        </span>
        {props.children}
      </P>
    </div>
  </div>
)

// #endregion WRAPPERS

// #region TIPOGRAFIA

const H1 = props => (
  <div>
    <h1
      className={`font-weight-bolder h1 d-none d-md-block ${props.className}`}
      style={{
        /* lineHeight: '4rem', */
        ...props.style,
      }}
    >
      {props.children}
    </h1>
    <h1
      className={`font-weight-bolder h3 d-md-none text-center ${props.className}`}
      style={{
        /* lineHeight: '4rem', */
        ...props.style,
      }}
    >
      {props.children}
    </h1>
  </div>
)

const H2 = props => (
  <h2
    className={`h2 ${props.className}`}
    style={{
      ...props.style,
    }}
  >
    {props.children}
  </h2>
)

const P = props => (
  <p
    className={`h6 text-sm-justify ${props.className}`}
    style={{
      ...props.style,
    }}
  >
    {props.children}
  </p>
)

// #endregion TIPOGRAFIA

export { Linea, Dot, Arrow, CuadroBordeado, TextoFoto, Li, H1, H2, P }
