import React from "react"
import { Link } from "gatsby"

import Image from "../../Image/Image"

const UpperHeader = React.memo(props => (
  <div className="col-12 pb-lg-3 d-none d-lg-block">
    <div className="row align-items-center justify-content-center">
      <Logo />
      <Sociales redes_sociales={props.redes_sociales} paises={props.paises} />
    </div>
  </div>
))

const Logo = React.memo(() => (
  <div className="col-6 text-center">
    <div className="row justify-content-center">
      <div className="col-lg-4 col-md-4">
        <Image type="FIXED_C" src="Tecnonorte/TNT-40.png" />
      </div>
    </div>
  </div>
))

const Sociales = React.memo(({ redes_sociales, paises }) => (
  <>
    <div className="col-4 text-center pt-4">
      <div className="row justify-content-end">
        {paises.map(p => (
          <Link to={p.link} className="px-3">
            <Image type="FIXED_25" src={p.imagen} />
          </Link>
        ))}
      </div>
      <div className="row justify-content-end">
        {redes_sociales.map(rs => (
          <a
            href={rs.link}
            target="_blank"
            rel="noopener noreferrer"
            className="px-3"
          >
            <Image type="FIXED_A" src={rs.imagen} />
          </a>
        ))}
      </div>
      <div className="row justify-content-end pt-2">
        <div className="col-12">
          <p className="font-italic text-primary text-right h5">
            COMPROMETIDOS CON VENEZUELA
          </p>
        </div>
      </div>
    </div>
    <div className="col-2" />
  </>
))

export default UpperHeader
