import React from "react"

import SeoHelmet from "../SEO/seoHelmet"
import NavBarL from "./NavBarL"
import NavBarSM from "./NavBarSM"
import UpperHeader from "./UpperHeader"

const Header = ({ redes_sociales, paises, pestanas, titulo }) => (
  <section id="inicio">
    <SeoHelmet titulo={titulo} />
    <div className="container-fluid w-100 m-0 p-0">
      <div className="row justify-content-center m-0 p-0">
        <UpperHeader redes_sociales={redes_sociales} paises={paises} />
        <NavBarL pestanas={pestanas} />
      </div>
      <div className="row m-0 p-0 justify-content-around text-white d-lg-none border-0">
        <NavBarSM iconos={redes_sociales} pestanas={pestanas} />
        <div className="py-5" />
        <div className="py-5" />
      </div>
    </div>
  </section>
)

export default React.memo(Header)
