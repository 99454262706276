import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronCircleUp } from "@fortawesome/free-solid-svg-icons"

import STYLES from "./FixedButton.module.scss"

export default React.memo(() => (
  <div className={`${STYLES.wrapper} m-5 d-none d-lg-block`}>
    <a href="#inicio">
      <FontAwesomeIcon
        className={STYLES.icon}
        icon={faChevronCircleUp}
        size="3x"
      />
    </a>
  </div>
))
