import React from "react"
import { Link } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"

import STYLES from "./Header.module.scss"

export default React.memo(({ pestanas }) => (
  <div className="col-12 px-lg-5" style={{ zIndex: 1 }}>
    <nav className="navbar navbar-expand-lg bg-primary d-none d-lg-block">
      <div className="collapse navbar-collapse">
        <div className="navbar-nav justify-content-around text-center h5 p-3">
          {pestanas.map((pestana, i) => (
            <div className={`${STYLES.dropdown} h-100`}>
              <Link
                className="nav-item nav-link text-white text-center"
                to={`/#${pestana.alias || pestana.nombre.toLowerCase()}`}
              >
                {pestana.nombre}
              </Link>
              {pestana.hijos && (
                <div className={`${STYLES.dropdownContent}`}>
                  {pestana.hijos.map(h => (
                    <div className={`${STYLES.dropdown2}`}>
                      <div className="col-12 secundary-hover">
                        <Link
                          className="nav-item nav-link text-white secundary-hover"
                          to={h.link}
                        >
                          {h.titulo}
                        </Link>
                      </div>
                      {h.nietos && (
                        <div className={`${STYLES.dropdownContent2}`}>
                          {h.nietos.map(n => (
                            <div className="col-12 secundary-hover">
                              <Link
                                className="nav-item nav-link text-white secundary-hover"
                                to={n.link}
                              >
                                {n.titulo}
                              </Link>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </nav>
  </div>
))
