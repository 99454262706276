import React, { PureComponent } from "react"
import { Link } from "gatsby"

import Fade from "react-reveal/Fade"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBars,
  faTimes,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons"

import Image from "../../Image/Image"

export default class NavBarSM extends PureComponent {
  state = {
    isNavOpen: false,
    lastScrollTop: 0,
    pestanaOpenIndex: null,
  }

  toggleNav = () => {
    this.setState(prev => ({
      isNavOpen: !prev.isNavOpen,
      pestanaOpenIndex: null,
    }))
  }

  togglePestana = index => {
    this.setState(prev => ({
      pestanaOpenIndex: prev.pestanaOpenIndex === index ? null : index,
    }))
  }

  render = () => (
    <div
      className="col-12 position-fixed"
      style={{
        zIndex: 100,
      }}
    >
      <div className="row align-content-center justify-content-between bg-primary">
        <div className="col my-auto">
          <div className="pl-md-3 pointer" onClick={this.toggleNav}>
            <FontAwesomeIcon
              icon={this.state.isNavOpen ? faTimes : faBars}
              size="2x"
            />
          </div>
        </div>
        <div className="col py-3 d-none d-sm-block text-center">
          <Image type="FIXED_C" src={"Tecnonorte/Tecnonorte02.png"} />
        </div>
        <div className="col my-auto py-3 d-sm-none text-center">
          <Image type="FIXED_C_MINUS" src={"Tecnonorte/Tecnonorte02.png"} />
        </div>
        <div className="col my-auto py-3 d-none d-md-block" />
      </div>

      <Fade left collapse when={this.state.isNavOpen}>
        <div
          className="row bg-primary"
          style={{
            boxShadow:
              "0 4px 8px -8px rgba(0, 0, 0, 0.5), 0 50px 20px -20px rgba(0, 0, 0, 0.19)",
          }}
        >
          <div className="col">
            <div className="row align-content-center">
              {this.props.pestanas.map((pestana, i) => (
                <>
                  {pestana.hijos ? (
                    <>
                      <div className="col-6 text-center py-2">
                        <div
                          className="row mx-auto my-auto nav-item nav-link text-white pointer secundary-hover"
                          onClick={() => this.togglePestana(i)}
                        >
                          {pestana.nombre}
                          <span className="pl-2">
                            <FontAwesomeIcon
                              icon={
                                this.state.pestanaOpenIndex === i
                                  ? faChevronUp
                                  : faChevronDown
                              }
                            />
                          </span>
                        </div>
                      </div>
                      {this.state.pestanaOpenIndex === i && (
                        <div className="col-12 text-center py-2">
                          <div
                            className="row animated fadeInRight"
                            style={{
                              backgroundColor: "#034991",
                            }}
                          >
                            {pestana.hijos.map(hijo => (
                              <Link
                                className="col-6 py-2 secundary-hover w-100 text-white h-100 my-auto"
                                to={hijo.link}
                                onClick={this.toggleNav}
                              >
                                <div>{hijo.titulo}</div>
                              </Link>
                            ))}
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="col-6 text-center py-2">
                      <Link
                        className="nav-item nav-link text-white secundary-hover"
                        to={`/#${pestana.alias ||
                          pestana.nombre.toLowerCase()}`}
                        onClick={this.toggleNav}
                      >
                        {pestana.nombre}
                      </Link>
                    </div>
                  )}
                </>
              ))}
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="row justify-content-center">
                  {this.props.iconos.map(a => (
                    <a
                      href={a.link}
                      target="_blank"
                      className="col-3 bg-primary justify-content-center py-3"
                    >
                      <Image
                        type="FIXED_A"
                        src={a.imagen}
                        className="mx-auto my-auto d-block"
                      />
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  )
}
