import React from "react"
import { Link } from "gatsby"

const Boton = React.memo(props => (
  <div className="row align-content-center justify-content-center px-3 py-5">
    <Link to={props.linkTo} className=" col-lg-4 col-md-6 col-sm-6">
      <input
        className="w-100 py-3 bg-primary border border-primary secundary-hover text-white font-weight-bold"
        type="button"
        value={props.text}
      />
    </Link>
  </div>
))

export default Boton
