import React from "react"

import ImageFluid500 from "../Image/ImageFluid500"
import ImageFluid1000 from "../Image/ImageFluid1000"
import ImageFluid1800 from "../Image/ImageFluid1800"
import ImageFixed25 from "./ImageFixed25"
import ImageFixedA from "./ImageFixedA"
import ImageFixedB from "./ImageFixedB"
import ImageFixedC from "./ImageFixedC"
import ImageFixedCMinus from "./ImageFixedCMinus"
import ImageFixedD from "./ImageFixedD"

const Image = ({ type, ...props }) => {
  switch (type) {
    case "FLUID_500":
      return <ImageFluid500 {...props} />
    case "FLUID_1000":
      return <ImageFluid1000 {...props} />
    case "FLUID_1800":
      return <ImageFluid1800 {...props} />
    case "FIXED_25":
      return <ImageFixed25 {...props} />
    case "FIXED_A":
      return <ImageFixedA {...props} />
    case "FIXED_B":
      return <ImageFixedB {...props} />
    case "FIXED_C":
      return <ImageFixedC {...props} />
    case "FIXED_C_MINUS":
      return <ImageFixedCMinus {...props} />
    case "FIXED_D":
      return <ImageFixedD {...props} />
    default:
      return <ImageFluid500 {...props} />
  }
}

export default React.memo(Image)
