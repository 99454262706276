import React from "react"

import Header from "./Header/Header"
import Footer from "./Footer/Footer"
import FixedButton from "./FixedButton/FixedButton"

const Layout = props => (
  <div className="container-fluid m-0 p-0">
    <Header
      redes_sociales={REDES_SOCIALES}
      paises={PAISES}
      pestanas={PESTANAS}
      titulo={props.titulo}
    />
    {props.children}
    <Footer
      redes_sociales={REDES_SOCIALES}
      pestanas={PESTANAS}
      colorIcono={props.colorIconoFooter}
    />
    <FixedButton />
  </div>
)

const REDES_SOCIALES = [
  {
    imagen: "Home/IconosSociales/Instagram.png",
    link: "https://www.instagram.com/grupotecnonorte/",
    nombre: "Instagram",
  },
  {
    imagen: "Home/IconosSociales/Linkedin.png",
    link: "https://www.linkedin.com/company/grupo-tecnonorte/",
    nombre: "LinkedIn",
  },
  {
    imagen: "Home/IconosSociales/Youtube.png",
    link: "https://www.youtube.com/channel/UCb1_1txvmVCZIS-018kPTxQ",
    nombre: "YouTube",
  },
  {
    imagen: "Home/IconosSociales/Twitter.png",
    link: "https://twitter.com/GrupoTecnonorte",
    nombre: "Twitter",
  },
]

const PAISES = [
  {
    imagen: "Home/Contacto/FLAG_VE.png",
    link: "/#contacto-VE",
    nombre: "Venezuela",
  },
  {
    imagen: "Home/Contacto/FLAG_COL.png",
    link: "/#contacto-CO",
    nombre: "Colombia",
  },
  {
    imagen: "Home/Contacto/FLAG_PERU.png",
    link: "/#contacto-PE",
    nombre: "Perú",
  },
]

const PESTANAS = [
  {
    nombre: "INICIO",
    hijos: [
      {
        titulo: "INICIO",
        link: "/#inicio",
      },
      {
        titulo: "GRUPO TECNONORTE",
        link: "/#grupo",
      },
      {
        titulo: "LÍDERES",
        link: "/#lideres",
      },
      {
        titulo: "PROVEEDORES",
        link: "/#proveedores",
      },
      {
        titulo: "CLIENTES INDUSTRIALES",
        link: "/#clientes-industriales",
      },
      {
        titulo: "CLIENTES COMERCIALES",
        link: "/#clientes-comerciales",
      },
      {
        titulo: "CÁMARAS A LAS QUE PERTENECEMOS",
        link: "/#camaras",
      },
    ],
  },
  {
    nombre: "CONÓCENOS",
    alias: "historia",
    hijos: [
      {
        titulo: "NUESTRA HISTORIA",
        link: "/#historia",
      },
      {
        titulo: "VISIONARIOS",
        link: "/#visionarios",
      },
      {
        titulo: "SEDES",
        link: "/#sedes",
      },
      {
        titulo: "VISIÓN Y MISIÓN",
        link: "/#vision-mision",
      },
      {
        titulo: "NUESTROS PRINCIPIOS",
        link: "/#principios",
      },
      {
        titulo: "CONTAMOS CON",
        link: "/#contamos",
      },
    ],
  },
  {
    nombre: "OFRECEMOS",
    hijos: [
      /*{
        titulo: "TODAS NUESTRAS SOLUCIONES",
        link: "/servicios",
      },*/
      {
        titulo: "SISTEMAS DE REFRIGERACIÓN",
        link: "/servicios/SistemaRefrigeracion",
        nietos: [
          {
            titulo: "SISTEMAS DE FREÓN",
            link: "/servicios/SistemaRefrigeracion/#sistemas-freon",
          },
          {
            titulo: "SISTEMAS DE AMONÍACO",
            link: "/servicios/SistemaRefrigeracion/#sistemas-amoniaco",
          },
          {
            titulo: "SISTEMAS DE GLICOL",
            link: "/servicios/SistemaRefrigeracion/#sistemas-glicol",
          },
          {
            titulo: "SISTEMAS DE CO2",
            link: "/servicios/SistemaRefrigeracion/#sistemas-co2",
          },
          {
            titulo: "SISTEMAS WATERLOOP",
            link: "/servicios/SistemaRefrigeracion/#sistemas-waterloop",
          },
        ],
      },
      {
        titulo: "CAVAS",
        link: "/servicios/Cavas",
      },
      {
        titulo: "MÁQUINAS DE HIELO",
        link: "/servicios/MaquinasHielo",
      },
      {
        titulo: "EXHIBIDORAS",
        link: "/servicios/Exhibidoras",
      },
      {
        titulo: "ESTANTERÍAS",
        link: "/servicios/Estanterias",
      },
      {
        titulo: "CHECK OUT",
        link: "/servicios/CheckOut",
      },
      {
        titulo: "CARROS PARA SUPERMERCADOS",
        link: "/servicios/CarrosSupermercado",
      },
      {
        titulo: "EQUIPAMIENTO GASTRONÓMICO",
        link: "/servicios/EquipamientoGastronomico",
      },
      {
        titulo: "EQUIPAMIENTO PARA ANDENES DE CARGA",
        link: "/servicios/AndenesCarga",
      },
      {
        titulo: "REPUESTOS",
        link: "/servicios/Repuestos",
      },
      {
        titulo: "AIRES ACONDICIONADOS",
        link: "/servicios/AiresAcondicionados",
      },
    ],
  },
  { nombre: "ÁREAS DE NEGOCIO", alias: "areas-de-negocio" },
  {
    nombre: "SERVICIO TÉCNICO",
    alias: "servicio-tecnico",
    hijos: [
      {
        titulo: "SERVICIO TÉCNICO POSTVENTA",
        link: "/#servicio-tecnico",
      },
      {
        titulo: "CONTROL Y MONITOREO REMOTO",
        link: "/#control-y-monitoreo",
      },
    ],
  },
  {
    nombre: "NOTICIAS Y EVENTOS",
    alias: "eventos",
    hijos: [
      {
        titulo: "EVENTOS",
        link: "/#eventos",
      },
      {
        titulo: "ÚLTIMAS NOTICIAS",
        link: "/#noticias",
      },
    ],
  },
  { nombre: "TRABAJA CON NOSOTROS", alias: "trabaja-con-nosotros" },
  { nombre: "CONTÁCTANOS", alias: "contactanos" },
]

export default React.memo(Layout)
