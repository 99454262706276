import React from "react"
import { Helmet } from "react-helmet"

const PRECONNECT = [
  "https://syndication.twitter.com",
  "https://scontent.cdninstagram.com",
  "https://api.instagram.com",
  "https://platform.twitter.com",
  "https://cdn.syndication.twimg.com",
]

export default React.memo(props => (
  <Helmet htmlAttributes={{ lang: "es" }}>
    <meta charset="utf-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, viewport-fit=cover"
    />
    <title>{`Tecnonorte | ${props.titulo}`}</title>

    <meta
      name="keywords"
      content="Venezuela, Neveras industriales, Refrigeración industrial, Cavas, Refrigeración industrial en Venezuela, Supermercados, Evaporador, Condensadores, Hielo, Estantería, Mueble de caja"
    />
    <meta name="description" content="Refrigeración industrial en Venezuela" />
    {PRECONNECT.map(p => (
      <link rel="preconnect" href={p} crossorigin />
    ))}
  </Helmet>
))
