import React from "react"
import { Link } from "gatsby"

import Image from "../../Image/Image"

const Footer = ({ redes_sociales, pestanas, colorIcono }) => (
  <div className="container-fluid bg-primary text-primary px-lg-5 px-md-5 px-sm-0 pb-5">
    <div className="row justify-content-center">
      <div className="col d-none d-lg-block text-center mt-n4">
        <Image
          type="FIXED_B"
          src={`Tecnonorte/Icono${colorIcono || "AzulClaro"}.png`}
        />
      </div>
    </div>
    <div className="row justify-content-center align-content-center mt-4 pt-3">
      <div className="col-lg-3 col-sm-12 my-auto mx-auto text-center">
        <Image type="FIXED_C" src="Tecnonorte/Tecnonorte02.png" />
      </div>
      <div className="col-lg-6 col-sm-12 py-3">
        <div className="row">
          {pestanas.map((pestana, i) => (
            <Link
              key={i}
              className="col-4 col-lg-3 text-center py-3 secundary-hover text-white my-auto pointer"
              to={`/#${pestana.alias || pestana.nombre.toLowerCase()}`}
            >
              {pestana.nombre}
            </Link>
          ))}
        </div>
      </div>
      <div className="col-lg-3 col-md-12 col-sm-12">
        <div className="row align-content-center justify-content-center h-100">
          {redes_sociales.map(a => (
            <a
              href={a.link}
              key={a.nombre}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="col py-3 h-100 secundary-hover">
                <Image type="FIXED_A" src={a.imagen} className="m-auto" />
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  </div>
)

export default React.memo(Footer)
