import React from "react"
import STYLES from "./Loading.module.scss"

const Loading = () => (
  <div className={STYLES.container}>
    <div className={STYLES.wrapper}>
      <div className={STYLES.loader} />
    </div>
  </div>
)

export default React.memo(Loading)
